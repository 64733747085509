import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

export const url = "api/v1/guarantor";

export async function searchGuarantors(request) {
  return await ApiService.post(url + "/search", request as never)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function addGuarantor(guarantor) {
  return await ApiService.post(url, guarantor as never).catch(
    ({ response }) => {
      processErrors(response);
    }
  );
}

export async function getGuarantor(guarantorId) {
  return await ApiService.get(url + "/" + guarantorId)
    .then((res) => {
      const item = res.data;
      return item;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function updateGuarantor(guarantor) {
  if (guarantor) {
    return await ApiService.put(url + "/" + guarantor.id, guarantor as never)
      .then((res) => {
        return res;
      })
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}

export async function deleteGuarantor(guarantor) {
  if (guarantor) {
    await ApiService.delete(url + "/" + guarantor?.id)
      .then()
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}

export async function checkGuarantorOverlap(patientPayer) {
  return await ApiService.post(url + "/checkGuarantorOverlap", patientPayer as never)
    .then((res) => {
      const result = res.data;
      return result;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}
